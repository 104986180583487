.title-container{
    height: 290px;
    width: 350px;
    text-align: center;
    line-height: 250px;
    position: relative;
    border-radius: 10px;
    display: inline-block;
    margin:10px;
}
.bg-img{
    position: absolute;
    height: 100%;
    left: 0px;
    top:0px;
}
.title-text{
    position: absolute;
    left: 100px;
    font-size: 2.8rem;
    font-weight: bold;
    font-family: 'Open sans', sans-serif;
    color: #311B92;
}
@media only screen and (max-width: 600px) {
    .title-container{
        height: 150px;
        width: 200px;
        line-height: 150px;
    }
    .title-text{
        left:40px;
        font-size: 2.2rem;
    }
}