.modal.bottom-sheet {
  min-height: 70vh;
  height: 100%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.modal {
  background-color: #fff;
  overflow: hidden;
}
.modal-content {
  height: calc(100% - 65px);
  text-align: left;
}
.preview-container {
  width: 110vw;
  margin-left: calc(-5vw - 24px);
  max-height: 30vh;
  white-space: nowrap;
  overflow-x: scroll;
  padding: 0px 20px;
  text-align: center;
}
.preview-container::-webkit-scrollbar {
  display: none;
}
.preview-container > img {
  display: inline-block;
  margin: 0px 5px;
  max-height: 25vh;
}
.project-text {
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.project-text > p {
  overflow: auto;
  padding: 0px 5px;
}
.project-text > p::-webkit-scrollbar {
  width: 2px;
}
.project-name {
  font-size: 2rem;
  font-weight: bold;
}
.modal-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  line-height: 54px;
  height: 65px !important;
  padding: 4px 24px 20px 24px !important;
  text-align: left !important;
}
.proj-links {
  display: inline-block;
  position: relative;
}
.proj-links > div {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
}
.proj-links > div > a > i {
  color: white;
  line-height: 40px;
  vertical-align: super;
}
.proj-github {
  background-color: #263238;
}
.proj-github > a > img {
  height: 24px;
  width: 24px;
  line-height: 0px;
}
.proj-live {
  background-color: #1565c0;
}
.proj-android {
  background-color: #a4c639;
}
.proj-date {
  font-size: 0.8rem;
  float: right;
  color: #37474f;
}
.proj-hide {
  display: none !important;
}
@media only screen and (max-width: 600px) {
  .modal.bottom-sheet {
    min-height: 80vh;
  }
  .project-name {
    font-size: 1.8rem;
  }
  .picture-container {
    right: 0px;
  }
}
