#Testimonial {
  position: relative;
  min-height: 60vh;
  max-width: 100vw;
  text-align: center;
  margin-top: 20vh;
}
.doodle-testimonial {
  position: absolute;
  top: 0vh;
  left: 0px;
  height: 100%;
  width: 100%;
  overflow-y: inherit;
  max-width: 100vw;
}
.doodle-testimonial > img {
  height: 75%;
  margin: 0vh calc(25% / 2);
  margin-top: -10vh;
  opacity: 0.3;
}
.testimonial-container {
  position: relative;
  text-align: center;
  width: 75%;
  margin: 0px auto;
}
.testimonial-container img {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  float: left;
  margin-right: 15px;
  margin-top: 5px;
}
.testimonial-text-container {
  padding-top: 50px;
  text-align: left;
}
.person-name {
  font-size: 1.3rem;
  display: block;
}
.person-name > a {
  color: #01579b;
}
.person-position {
  font-size: 1.2rem;
}
.quote {
  font-size: 1.5rem;
  opacity: 0.8;
  line-height: 30px;
  font-weight: 800;
  font-family: serif;
  margin: 0px 5px;
}
.person-testimonial {
  font-size: 1.7rem;
  margin-bottom: 20px;
  font-weight: 300;
  display: block;
}

.get-feedback {
  margin-top: 15vh;
}

@media only screen and (max-width: 600px) {
  .testimonial-container {
    top: 0vh;
    width: 80%;
  }
  .quote {
    font-size: 1.5rem;
  }
  .doodle-testimonial {
    top: 0vh;
  }
}
