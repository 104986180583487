.button {
  border-radius: 20px;
  height: 40px;
  display: inline-block;
  padding: 5px 25px;
  line-height: 29px;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease;
  margin-right: 15px;
}
.button:hover {
  box-shadow: 0px 0px 5px grey;
}
.button-title {
  font-weight: bold;
  letter-spacing: 0.7px;
  font-size: 13px;
}
.button-icon {
  vertical-align: middle;
  font-size: 1.4rem;
}
.blue {
  background-color: #2962ff;
}
.red {
  background-color: #d44638;
}
.purple {
  background-color: #4a148c;
}

@media only screen and (max-width: 600px) {
  .button {
    margin: 0px 8px;
  }
}
