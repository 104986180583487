#HelloWorld {
  height: 85vh;
}
.body-container {
  position: relative;
}
.identity-container {
  margin-left: 7vw;
  float: left;
  top: 0px;
  position: relative;
}
.mesh-top {
  height: 200px;
  width: 200px;
  margin-bottom: -40px;
  margin-left: -2vw;
  position: absolute;
  display: none;
  /* transition: all 0.15s ease; */
}
.my-name {
  margin-top: calc(200px - 10vh);
  letter-spacing: 1px;
  display: inline-block;
  font-size: 3rem;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
}
.title-dots {
  width: 85px;
}
.title-dots,
.title-name {
  display: inline-block;
  margin-right: 10px;
}
.title-name {
  font-size: 1.5em;
}
.title-dots > div {
  transition: all 0.2s ease;
  height: 15px;
  width: 15px;
  margin: 0px 5px;
  display: inline-block;
  border-radius: 100%;
  cursor: pointer;
}
.title-dots > div:hover {
  height: 18px;
  width: 18px;
}
.dot-active {
  height: 18px !important;
  width: 18px !important;
}
.dot-one {
  background-color: #0091ea;
}
.dot-two {
  background-color: #ff9100;
}
.dot-three {
  background-color: #4a148c;
}

.picture-container {
  text-align: center;
  padding-top: 10vh;
  position: absolute;
  right: 7vw;
}
.picture-container > a > img {
  border-radius: 100%;
  border: 5px solid #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}
.contacts-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.parallax-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 90%;
  box-sizing: border-box;
  z-index: -1;
}
.parallax-wrapper > img {
  display: inline-block;
  position: absolute;
}
.splatter-home {
  height: 100%;
}
.doodle2-home,
.doodle-home {
  height: 100%;
  float: right;
  right: 0px;
  top: 0px;
  /*transition: all 0.15s ease;*/
}

@media only screen and (max-width: 800px) {
  .body-container {
    text-align: center;
    margin-top: -10vh;
  }
  .identity-container {
    position: relative;
    margin: 0px auto;
    float: none;
    display: inline-block;
  }
  .picture-container {
    float: none;
    width: 100%;
    padding-top: 5vh;
    position: relative;
    right: unset;
  }
  .picture-container > a > img {
    width: 65%;
    margin-left: 15px;
  }
  .contacts-container {
    justify-content: center;
    gap: 20px;
    flex-direction: column;
  }
  .mesh-top {
    display: none;
  }
  .my-name {
    font-size: 8vw;
    margin-top: 0px;
    letter-spacing: 0.3px;
  }
  .my-titles {
    text-align: left;
  }
  .title-name {
    font-size: 5.5vw;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .my-name {
    font-size: 2.3rem;
  }
  .picture-container > a > img {
    width: 35vw;
  }
  #HelloWorld {
    height: 80vh;
  }
}
