.tool-image{
    height: 100px;
    width: 100px;
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom:50px;
    display: inline-block;
    cursor: pointer;
}
.tool-image>img{
    max-height: 100%;
    max-width: 100%;
}

@media only screen and (max-width: 600px) {
    .tool-image{
        height: 70px;
        width: 70px;
        margin-right: 15px;
        margin-left: 15px;
        margin-bottom: 30px;
    }
}