.chip{
    height: 35px;
    padding: 5px 25px;
    background-color: #ECEFF1;
    font-size: 0.8rem;
    color: #37474F;
    border-radius: 30px;
    line-height: 25px;
    margin:5px 5px 5px 0px;
}
@media only screen and (max-width: 600px) {
    .chip{
        height: 25px;
        padding: 2px 15px;
        font-size: 0.74rem;
        color: #37474F;
        border-radius: 30px;
        line-height: 21px;
        margin:2px 2px 2px 0px;
    }
}