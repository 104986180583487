::-webkit-scrollbar{width:8px}::-webkit-scrollbar-track{box-shadow:inset 0 0 20px rgba(0,0,0,.3)}::-webkit-scrollbar-thumb{box-shadow:inset 0 0 200px rgba(0,0,0,.5);border-radius:5px;cursor:pointer}::-webkit-scrollbar-thumb:hover{background:#546c77}::-webkit-scrollbar-thumb:active{background:#43565f}
body, .App{
    overflow-x: hidden;
    max-width: 100vw;
}
.line-break-l{
    height: 5vh;
}
.line-break-m{
    height: 2.5vh;
}
.line-break-s{
    height: 1vh;
}
*{
    outline: none !important;
    -webkit-tap-highlight-color: transparent !important;
}
html {
	scroll-behavior: smooth;
}