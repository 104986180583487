#Footer{
    position: relative;
}
.color-box{
    background: linear-gradient(to right,#29B6F6 25%,#ffcd02 25%,#ffcd02 50%,#e84f47 50%,#e84f47 75%,#65c1ac 75%);
    position: absolute;
    content: '';
    height: 5px;
    right: 0;
    left: 0;
    top: 0;
}
.page-footer{
    height: 50px;
    text-align: center;
    line-height: 13px;
    font-size: 0.9rem;
    background-color: #37474F;
}
.page-footer>a{
    color:#ECEFF1;
    font-weight: 600;
    letter-spacing: 0.6px;
    text-decoration: underline;
}