#Contact{
    position: relative;
    min-height: 80vh;
    margin-top: 5vh;
    background-image: linear-gradient(transparent, rgba(236, 239, 241, 0.774));
    text-align: center;
}
.doodle-contact{
    position: absolute;
    top:0px;
    width: 100%;
    height: 100%;
    max-height: 90%;
    max-width: 100vw;
    z-index: 0;
}
.contact-card-doodle{
    position: absolute;
    bottom: 0px;
    right:0px;
    overflow: hidden;
    top:0px;
    left:0px;
    z-index: -1;
    border-radius: 15px;
}
.contact-card-doodle>.one{
    border-radius: 100%;
    border:15px solid white;
    background-color: #ffb74d;
    height: 350px;
    width: 350px;
    position: absolute;
    right:-150px;
    bottom: -120px;
    z-index: -1;
}
.contact-card-doodle>.two{
    border-radius: 100%;
    border:10px solid white;
    background-color: #29b6f6;
    height: 250px;
    width: 250px;
    position: absolute;
    bottom: -100px;
    right: 50px;
    z-index: -2;
}

.doodle-contact>img{
    max-height: 100%;
    max-width: 100%;
}
.contact-card{
    max-width: 600px;
    min-height: 370px;
    width: 95vw;
    padding: 30px 40px;
    border-radius: 15px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    display: inline-block;
    margin:15vh auto 0px auto;
    z-index: 10;
    background-color: #ffffff;
    position: relative;
    text-align: left;
}
.contact-title{
    font-size: 2rem;
    font-weight: bold;
    color: #37474F;
    letter-spacing: 0.5px;
}
.contact-subTitle{
    font-size: 1.1rem;
    color: #78909C;
    letter-spacing: 0.5px;
}
.contact-item{
    margin-bottom: 15px;
}
.contact-item>i{
    vertical-align: bottom;
    font-size: 25px;
    color: #546E7A;
}
.contact-text{
    font-size: 1.2rem;
    margin-left: 10px;
    letter-spacing: 0.5px;
    color: #263238;
}
.sponsor{
    font-weight: bold;
    font-size: 1.15rem;
}

@media only screen and (max-width: 600px) {
    #Contact{
        background-image: none;
        margin-top: 10vh;
        min-height: 60vh;
    }
    .doodle-contact{
        max-width: 1000vw;
        height: 100%;
        max-height: 100%;
        opacity: 0.5;
    }
    .doodle-contact>img{
        height: 100%;
        max-width: 1000vw;
    }
    .contact-card{
        box-shadow: none;
        width: 100vw;
        position: absolute;
        bottom:0px;
        left:0px;
        margin-top: 0px;
        height: 100%;
        padding: 30px;
        background-color: transparent;
    }
    .contact-card-doodle{
        border-radius: 0px;
    }
    .contact-card-doodle>.one{
        width: 250px;
        height: 250px;
        right:-100px;
        bottom: -100px;
        border:10px solid white;
    }
    .contact-card-doodle>.two{
        width: 150px;
        height: 150px;
        right: 60px;
        bottom: -60px;
    }
}